import { IProduct } from '../../../@generated/@types/contentful';
import { isTopPicksFilteringEnabled } from '../../../lib/getFeatureFlags';
import { useOrderLocation } from '../../../redux/hooks';
import { useProducts } from '../../../redux/hooks/domainMenu';
import { getContentfulProductIdsByFields } from '../../../common/helpers/getContentfulProductIdsByFields';
import { getProductByIds } from '../../../common/helpers/getProductByIds';
import { checkProductIsAvailable } from '../../../common/helpers/checkProductIsAvailable';

export default function useTopPicksFilteringFeature(): (product: IProduct) => boolean {
    const domainProducts = useProducts();
    const { isCurrentLocationOAAvailable } = useOrderLocation();

    if (!isTopPicksFilteringEnabled()) {
        return () => true;
    }

    return (product: IProduct) => {
        const domainProduct = getProductByIds(getContentfulProductIdsByFields(product.fields), domainProducts);

        // show all products for national menu
        if (!isCurrentLocationOAAvailable) return true;

        return checkProductIsAvailable(domainProduct);
    };
}
