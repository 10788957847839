import React from 'react';
import { createNextState } from '@reduxjs/toolkit';

import TopPicks from './topPicks';

import useTopPicksFilteringFeature from './useTopPicksFilteringFeature';
import VerticalProductCardContainer from '../../organisms/verticalProductCardContainer';
import { ITopPicksContainerProps } from './types';

const TopPicksContainer = ({ entry }: ITopPicksContainerProps): JSX.Element => {
    const topPicksFilter = useTopPicksFilteringFeature();

    if (!entry.fields?.category) {
        return null;
    }

    const { products } = entry.fields.category.fields;
    const visibleProducts = products.filter((product) => !!product.fields.isVisible);
    const availableProducts = visibleProducts.filter(topPicksFilter);

    if (!availableProducts.length) {
        return null;
    }

    const updatedEntry = createNextState(entry, (data) => {
        const newEntry = data;

        newEntry.fields.category.fields.products = availableProducts;
    });

    return (
        <TopPicks
            entry={updatedEntry}
            renderProductCard={({ isSideScroll, categoryUrl, product, className, gtmId }) => (
                <VerticalProductCardContainer
                    isSideScroll={isSideScroll}
                    category={categoryUrl}
                    item={product}
                    key={product.sys.id}
                    className={className}
                    gtmId={gtmId}
                    shouldPreloadImages
                    showDiscountBadge
                />
            )}
        />
    );
};

export default TopPicksContainer;
